const { subscribeMutationObserver } = require('../utils/helpers');
const { Swiper, Navigation } = require('swiper');

Swiper.use([Navigation]);

function initRecommendationsSlider() {
    const swiper = new Swiper('.js-recommendations-carousel', {
        slidesPerView: 2,
        slidesPerGroup: 2,
        watchOverflow: true,
        breakpoints: {
            768: {
                slidesPerView: 4,
                slidesPerGroup: 4
            }
        },
        navigation: {
            nextEl: '.js-recommendations-next',
            prevEl: '.js-recommendations-prev'
        }
    });
}

module.exports.init = function init() {
    const $recommendationWrapper = $('.js-recommendations-wrapper');

    subscribeMutationObserver($recommendationWrapper, function() {
        if ($('.js-recommendations-carousel').length) {
            initRecommendationsSlider();
            $recommendationWrapper.trigger('disconnectObserver');
        }
    });
}
